import { Translation } from "i18n/config";
import {
	ChallengesProfileTranslation,
	ChallengesTranslation,
	EnableCompanyChallengeTranslation,
	EnableUniversityChallengeTranslation,
} from "./typing";
import { MicroBusinessProfileTypes } from "constants/Profiles";
import * as React from "react";

export const ChallengesTexts: Translation<ChallengesTranslation> = {
	es: {
		challenges: "Retos",
		configurationTitle: "Configurar retos",
		enableChallengeWithOtherCompanyTitle: 'Habilitar opción "Retos de Empresas"',
		enableChallengeWithOtherCompanyDesc:
			"Vinglet vincula Empresas con Universidades a través de los retos que realizan las Empresas.  Muchas empresas internacionales utilizarán está opción para validar o desarrollar producto o servicio en otro país donde tienen intención de penetrar y que la competencia no lo sepa. En el caso, de que ustedes habiliten esta opción, recibirán propuestas de empresas donde les solicitarán sus servicios para escalar su producto o servicio en su país.Así mismo, también nos encontraremos con casos de empresas nacionales que solicitarán sus servicios para hacer la misma actividad. Cada vez que una Empresa hace un reto se le pone a disposición la posibilidad de solicitar los servicios de las Universidades que han firmado convenio con Vinglet. Por último, es importante que identifiquen un email para recibir las propuestas, así como los sectores comerciales que se sienten cómodos para ser vinculados con las empresas.",

		enableChallengeWithOtherUniversityTitle: 'Habilitar opción "Retos con otras Universidades"',
		enableChallengeWithOtherUniversityDesc:
			"Vinglet vincula vuestra Universidad con Universidades nacionales e internacionales a través de los retos. Cada vez que una Universidad crea un reto y tiene habilitada la opción compartir reto con otra Universidad, podrá enviar una solicitud para que otra Universidad valore la propuesta de realizar el reto de forma conjunta. Ambos vais a tener el perfil de administrador del reto, por lo que ambos podréis cargar, editar, modificar, eliminar y publicar el reto. Para recibir propuestas de otras Universidades, identifique email para recibir las propuestas y facultad Universitaria.",

		enableChallengeWithOtherIncubatorTitle: 'Habilite la opción "Desafíos con otras incubadoras"',
		enableChallengeWithOtherIncubatorDesc:
			"Vinglet vincula su Incubadora con Incubadoras nacionales e internacionales a través de retos y hackathons. Cada vez que una Incubadora crea un desafío o un hackathon tiene la opción de compartir la propuesta con un homólogo que este en nuestra base de datos. Podrá enviar una solicitud para que otra Incubadora evalúe la propuesta para llevar a cabo el reto o hackathon juntos. Ambos tendrán el perfil de administrador del desafío, por lo que ambos pueden cargar, editar, modificar, eliminar y publicar el desafío. Para recibir propuestas de otras Incubadoras, identifique un correo electrónico para recibir las propuestas y las etiquetas correspondientes.",

		enableChallengeWithOtherAcceleratorTitle: 'Habilite la opción "Desafíos con otras aceleradoras"',
		enableChallengeWithOtherAcceleratorDesc:
			"Vinglet vincula su Aceleradora con Aceleradoras nacionales e internacionales a través de retos y hackathons. Cada vez que un Acelerador crea un desafío o un hackathon tiene la opción de compartir la propuesta con un homólogo que este en nuestra base de datos. Podrá enviar una solicitud para que otra Aceleradora evalúe la propuesta para llevar a cabo el reto o hackathon juntos. Ambos tendrán el perfil de administrador del desafío, por lo que ambos pueden cargar, editar, modificar, eliminar y publicar el desafío. Para recibir propuestas de otros Aceleradoras, identifique un correo electrónico para recibir las propuestas y las etiquetas correspondientes.",

		enableChallengeWithOtherInstitutionTitle: 'Habilite la opción "Desafíos con otras instituciones"',
		enableChallengeWithOtherInstitutionDesc:
			"Vinglet vincula su Institución con Instituciones nacionales e internacionales a través de retos y hackathons. Cada vez que una Institución crea un desafío o un hackathon tiene la opción de compartir la propuesta con un homólogo que este en nuestra base de datos. Podrá enviar una solicitud para que otra Institución evalúe la propuesta para llevar a cabo el reto o hackathon juntos. Ambos tendrán el perfil de administrador del desafío, por lo que ambos pueden cargar, editar, modificar, eliminar y publicar el desafío. Para recibir propuestas de otras Instituciones, identifique un correo electrónico para recibir las propuestas y las etiquetas correspondientes.",

		youMustBeEitherCreatorOrInvitedToSeeTheChallenge:
			"Para ver correctamente toda la información de este Reto usted debe ser el creador o ser invitado a participar del mismo.",
		challengesDescriptionIntro: "En el apartado de descripción podrás detallar tu respuesta. Además, tiene la herramienta de hipervínculos.  Enlaces que nos van a permitir la interconexión con otros documentos que queramos mostrar para ayudar a tender mejor nuestra propuesta. También sirve para mostrar documentos o archivos que queremos compartir si estamos buscando conformar equipos para dar respuesta al reto, innovación abierta, corporate venturing, etc.",
		type: "Tipo",
		enable: "Habilitar",
		enabled: "Habilitado",
		disable: "Deshabilitar",
		challengeWithOtherUniversity: "Retos con otra Universidad",
		challengeInviteRestriction: "Sólo se puede invitar a un Universidad",
		situationApproved: "ACEPTADO",
		situationDenied: "DENEGADO",
		situationPending: "PENDIENTE",
		situationFinish: "FINALIZADO",
		startDate: "Fecha inicio",
		endDate: "Fecha fin",
		inCharge: "Responsable",
		invitedUniversity: "Universidad invitada",
		create: "Crear",
		invitedBy: "Invitado por",
		email: "Email",
		acepted: "Aceptadas",
		pendings: "Pendientes",
		denied: "Denegadas",
		finished: "Finalizadas",
		withoutThematic: "Sin temática",
		seeMore: "Ver más",
		thematic: "Temáticas",
		selectedFile: "Archivo seleccionado",
		missing: "Sin completar",
		dragAndDropFileHelp: "Arrastre el listado de mails o simplemente cliquee para seleccionar el archivo.",
		mentors: "Mentores",
		elearning: "E-learning",
		writeNameAndSurname: "Escribe tu nombre y apellidos",
		writeEmail: "Escribe tu email",
		affair: "Asunto",
		receiveEmail: "Recibirás una copia del mensaje a modo de prueba",
		perfilCreador: "Perfil creador",
		perfilInvited: "Perfil invitado",
		platforms: "Plataformas de videoconferencias y herramientas de trabajo",
		platformsSubtitle:
			"Espacio para vincular vuestro perfil de plataforma de videoconferencias y herramientas de trabajo en equipo",
		meetTheTeam: "Conoce al equipo",
		images: "Adjuntar fotografías",
		imagesDescription:
			"Este apartado es para que puedas mostrar 4 imágenes ilustrativas o fotografías que representen tu propuesta para resolver el reto, una historia, un elemento de contenido, categoría o módulo. <br> Sólo documentos formato JPG o PNG (tamaño máximo de archivo: 25 MB)",
		attachedDocument: "Adjuntar documento anexo",
		attachedDocumentDescription: "Adjunta documento anexo para ayudar a entender mejor tu propuesta.",
		importantResponsability:
			"IMPORTANTE: no nos hacemos responsables de marcas y patentes, le sugerimos que no suba documentación sensible.",
		general: "General",
		myChallenges: "Mis propuestas de reto",
		mentorRelationship: "Relación de mentores",
		solicitedDescription: "Descripción de lo solicitado",
		elearningContent: "Contenido de E-learning",
		listOfProposalsFor: "Listado de Propuestas para",
		team: "Equipo",
		searching: "Buscando",
		searchMentors: "Buscar mentores",
		searchMentorsDescription:
			'Si el mentor está dentro de Vinglet, escribe el nombre de usuario por el cual se ha identificado. Los mentores deben registrarse dentro de Vinglet primero como persona física y seguidamente crear el perfil "Asesor" e identificar coste hora “0”.',
		spaceToInfiteUniversityDescription:
			"Espacio para invitar a realizar un Reto a otras Universidades nacionales o internacionales",
		select: "Selecciona",
		selected: "Seleccionado",
		acceptRequest: "Aceptar solicitud",
		visit: "Visitar",
		challengeSignUp: "Inscribirme al Reto",
		inviteUniversityAdvice:
			"Si la Universidad acepta la propuesta aparecerá vinculado al reto. Por lo contrario, sino lo acepta la propuesta recibirá un email con la denegación de la Universidad.",
		addUniversity: "Agregar universidad",
		addUniversityAdvice:
			'Recuerda que aquí se listaran las universidades que hayan aceptado recibir "Retos", si la universidad que usted está buscando no se encuentra en este listado, le sugerimos que se ponga en contacto con la universidad con la finalidad de que puedan realizar "Retos"',
		milestones: "Hitos",
		milestonesDesc: "Como mínimo 1 hito, y como máximo 6.",
		noSearchResult: "No hay resultados para la búsqueda seleccionada todavía.",
		adminBy: "Administrado por",
		relatedProfiles: "Perfiles Involucrados",
		lookForNewProfiles: "Conoce Nuestras Ofertas de Talento",
		attachedDocuments: "Documentos Anexos",
		knowTheTeam: "Conoce al Equipo",
		knowTheMentor: "Conoce a los mentores ",
		seeImage: "Imagenes",
		missingDescription: "Esta propuesta no tiene descripción",
		missingImages: "Esta propuesta no tiene imágenes",
		admin: "Administrador",
		missingDocuments: "Esta propuesta no tiene documentos adjuntos",
		missingMentors: "Esta propuesta no tienen mentores",
		addNewMentores: "Agregar nuevos mentores",
		editMentores: "Editar mentores",
		editMentoresDesc:
			"Desde aqui podras seleccionar los mentores habilitados para dar soporte dentro de las propuestas del Reto.",
		selectThis: "Seleccionar",
		generalMenu: "General",
		imagenes: "Imagenes",
		documents: "Documentos",
		deleteProposal: "Eliminar propuesta",
		settingFor: "Configuración de",
		settingForStreamingInfoTitle: "¿Cómo incluir correctamente el link para tus canales de Streaming?",
		settingForStreamingInfoDescParagraph1:
			"- Debe seleccionar cada link correspondiente de cada una de las plataformas",
		settingForStreamingInfoDescParagraph2: (socialMediaProfileRoot) => (
			<>
				- Debe eliminar la parte inicial del link, es decir, si usted por ejemplo desea ingresar un link con el
				siguiente link <b>&quot;{socialMediaProfileRoot}1234567&quot;</b>, usted solo debería ingresar la última parte:
				<b>&quot;1234567&quot;</b>
			</>
		),

		backToProposal: "Volver a la propuesta",
		max4Docs: "Máximo 4 archivos",
		pdfFormat: "Los archivos deben estar en formato PDF, PowerPoint, Excel o Word",
		limit5MB: "Los archivos no pueden superar los 5MB cada uno.",
		addDocumentsDescription: "Adjunta los documentos anexos para ayudar a entender mejor tu propuesta.",
		max: "Máximo",
		files: "archivos",
		dragPDF: "Arrastre el documento o simplemente cliquee para seleccionar el archivo.",
		remove: "Eliminar",
		errorUploadingFiles: "Un error ocurrió al tratar de subir el archivo.",
		deleteWarning: "Una vez se haya eliminado la propuesta, la accion no puede ser revertida. Por favor se prudente.",
		beforeContinue: "Antes de seguir.",
		configureChallenges: "Configurar retos",
		configureChallengesDes:
			"Para poder concretar con exito la creacion de Retos dentro de Vinglet primero deberas configurar ciertas opciones para que podamos brindarte una mejor experiencia.",
		addMentoresToChanllenge: "Espacio para adjuntar tus mentores al Reto",
		addMentoresTitle: "Agrega los mentores correspondientes rellenando la casilla que se encuentra a continuacion.",
		addMentoresDesc:
			"Tenga en cuenta que una vez que se apliquen los nuevos mentores ya no se podra revertir el cambio. Le aconsejamos revisar bien los mentores agregados antes de continuar, muchas gracias.",
		alreadyAttachedMentorDesc:
			"Mentores ya adjuntados dentro del Reto (esto incluye los mentores agregados por los demas perfiles)",
		advisedProposals: "Propuestas Asesoradas",
		noChallengesYet: "Todavía no se han cargado Talents para esta propuesta.",
		challengesDescription: "Ingrese la descripción aquí mismo de su propuesta",
		maxDocsTitle: "Ha superado el límite de archivos permitidos.",
		maxDocsDescFirstPart: "Sólo se admiten como máximo",
		maxDocsDescSecondPart: "documentos para la propuesta.",
		savedCorrectly: "Se han guardado los datos con éxito",
		savedUncorrectly: " No se han podido guardar los datos.",
		savedDesc: "Ya están los datos actualizados dentro de tu propuesta.",
		noSavedDesc:
			"Disculpe las molestias, hubo un error desde nuestros servidores, considere reenviar el formulario de nuevo mas tarde.",
		challengeCreatedBy: (microBusinessProfileTypes: MicroBusinessProfileTypes) => {
			switch (microBusinessProfileTypes.toLowerCase()) {
				case MicroBusinessProfileTypes.UNIVERSITY:
					return "Reto creado por un perfil Universidad";
				case MicroBusinessProfileTypes.COMPANY:
					return "Reto creado por un perfil Empresa";
				case MicroBusinessProfileTypes.ACCELERATOR:
					return "Reto creado por un perfil Aceleradora";
				case MicroBusinessProfileTypes.INCUBATOR:
					return "Reto creado por un perfil Incubadora";
				case MicroBusinessProfileTypes.INSTITUTION:
					return "Reto creado por un perfil Institución";
				default:
					return "";
			}
		},
	},
	en: {
		challenges: "Challenges",
		configurationTitle: "Configure challenges",
		enableChallengeWithOtherCompanyTitle: 'Enable option "Business Challenges"',
		enableChallengeWithOtherCompanyDesc:
			"Vinglet links Companies with Universities through the challenges that Companies carry out. Many international companies will use this option to validate or develop a product or service in another country where they intend to penetrate and that the competition does not know. In the event that you enable this option, you will receive proposals from companies where they will request your services to scale your product or service in your country. Likewise, we will also find cases of national companies that will request their services to carry out the same activity. Every time a Company makes a challenge, the possibility is made available to request the services of the Universities that have signed an agreement with Vinglet. Finally, it is important that they identify an email to receive the proposals, as well as the commercial sectors that feel comfortable to be linked with the companies.",
		enableChallengeWithOtherUniversityTitle: 'Enable option "Challenges with other Universities"',
		enableChallengeWithOtherUniversityDesc:
			"Vinglet links your University with national and international Universities through challenges. Every time a University creates a challenge and has the option to share a challenge with another University enabled, it will be able to send a request for another University to evaluate the proposal to carry out the challenge together. Both of you will have the challenge administrator profile, so both of you can upload, edit, modify, delete and publish the challenge. To receive proposals from other Universities, identify email to receive the proposals and University faculty.",
		enableChallengeWithOtherIncubatorTitle: 'Enable the option "Challenges with other incubators"',
		enableChallengeWithOtherIncubatorDesc:
			"Vinglet links its Incubator with national and international Incubators through challenges and hackathons. Every time an Incubator creates a challenge or hackathon, they have the option of sharing the proposal with a counterpart in our database. You will be able to submit a request for another Incubator to evaluate the proposal to carry out the challenge or hackathon together. They will both have the admin profile of the challenge, so they can both upload, edit, modify, delete, and post the challenge. To receive proposals from other Incubators, identify an email to receive the proposals and the corresponding labels.",
		enableChallengeWithOtherAcceleratorTitle: 'Enable the option "Challenges with other accelerators"',
		enableChallengeWithOtherAcceleratorDesc:
			"Vinglet links its Accelerator with national and international Accelerators through challenges and hackathons. Every time an Accelerator creates a challenge or hackathon, they have the option to share the proposal with a peer in our database. You will be able to submit a request for another Accelerator to evaluate the proposal to run the challenge or hackathon together. They will both have the admin profile of the challenge, so they can both upload, edit, modify, delete, and post the challenge. To receive proposals from other Accelerators, identify an email to receive the proposals and the corresponding labels.",
		enableChallengeWithOtherInstitutionTitle: 'Enable the option "Challenges with other institutions"',
		enableChallengeWithOtherInstitutionDesc:
			"Vinglet links its Institution with national and international Institutions through challenges and hackathons. Every time an Institution creates a challenge or a hackathon, it has the option of sharing the proposal with a counterpart that is in our database. You can send a request for another Institution to evaluate the proposal to carry out the challenge or hackathon together. They will both have the admin profile of the challenge, so they can both upload, edit, modify, delete, and post the challenge. To receive proposals from other Institutions, identify an email to receive the proposals and the corresponding labels.",
		youMustBeEitherCreatorOrInvitedToSeeTheChallenge:
			"To correctly see all the information of this Challenge you must be the creator or be invited to participate in it.",
		challengesDescriptionIntro: "In the description section you can detail your answer. Also, it has the hyperlinks tool. Links that will allow us to interconnect with other documents that we want to show to help better understand our proposal. It also serves to show documents or files that we want to share if we are looking to form teams to respond to the challenge, open innovation, corporate venturing, etc.",
		type: "Type",
		enable: "Enable",
		enabled: "Enabled",
		disable: "Disable",
		challengeWithOtherUniversity: "Challenges with another University",
		challengeInviteRestriction: "You can only invite one University",
		situationApproved: "ACCEPTED",
		situationDenied: "DECLINATED",
		situationPending: "PENDING",
		situationFinish: "FINISHED",
		startDate: "Start date",
		endDate: "End date",
		inCharge: "In charge",
		invitedUniversity: "Invited University",
		create: "Create",
		invitedBy: "Invited by",
		email: "Email",
		acepted: "Acepted",
		pendings: "Pendings",
		denied: "Denied",
		finished: "Finished",
		withoutThematic: "Without thematic",
		seeMore: "See more",
		thematic: "Thematic",
		selectedFile: "Selected file",
		missing: "Field missing",
		dragAndDropFileHelp: "Drag the list of mails or simply click to select the file.",
		mentors: "Mentors",
		elearning: "E-learning",
		writeNameAndSurname: "Write your name and surname",
		writeEmail: "Write your email",
		affair: "Affair",
		receiveEmail: "You will receive a copy of the message as a test",
		perfilCreador: "Creator profile",
		perfilInvited: "Invited profile",
		platforms: "Video conferencing platforms and work tools",
		platformsSubtitle: "Space to link your video conferencing platform profile and teamwork tools",
		meetTheTeam: "Meet the team",
		images: "Attach photos",
		imagesDescription:
			"This section is for you to show 4 illustrative images or photographs that represent your proposal to solve the challenge, a story, a content element, category or module. <br> Only JPG or PNG format documents (maximum file size: 25 MB)",
		attachedDocument: "Attach attached document",
		attachedDocumentDescription: "Attach an attached document to help better understand your proposal.",
		importantResponsability:
			"IMPORTANT: we are not responsible for trademarks and patents; we suggest that you do not upload sensitive documentation.",
		general: "General",
		myChallenges: "My challenges proposals",
		mentorRelationship: "Mentor Relationship",
		solicitedDescription: "Description of the request",
		elearningContent: "E-learning content",
		listOfProposalsFor: "List of proposals for",
		team: "Team",
		searching: "Searching",
		searchMentors: "Mentors search",
		searchMentorsDescription:
			'If the mentor is within Vinglet, enter the username by which they have identified themselves. Mentors must first register within Vinglet as a natural person and then create the "Advisor" profile and identify cost hour "0".',
		spaceToInfiteUniversityDescription:
			"Space to invite to carry out a Challenge to other national or international Universities",
		select: "Select",
		selected: "Selected",
		acceptRequest: "Accept request",
		visit: "Visit",
		challengeSignUp: "Sign up for the Challenge",
		inviteUniversityAdvice:
			"If the University accepts the proposal, it will appear linked to the challenge. On the contrary, if the proposal does not accept it, you will receive an email with the refusal of the University.",
		addUniversity: "Add university",
		addUniversityAdvice:
			'Remember that here the universities that have accepted to receive "Challenges" will be listed. If the university you are looking for is not in this list, we suggest that you contact the university so that they can perform "Challenges"',
		milestones: "Milestones",
		milestonesDesc: "At least 1 milestone, and at most 6.",
		noSearchResult: "There are no results for the selected search yet.",
		adminBy: "Managed by ",
		relatedProfiles: "Involved Profiles",
		lookForNewProfiles: "Meet Our Talent Offers",
		attachedDocuments: "Attached Documents",
		knowTheTeam: "Meet the team",
		knowTheMentor: "Meet the mentors",
		seeImage: "Images",
		missingDescription: "This proposal has no description ",
		missingImages: "This proposal has no images",
		admin: "Manager",
		missingDocuments: "This proposal has no documents",
		missingMentors: "This proposal has no mentors",
		addNewMentores: "Add new mentores",
		editMentores: "Edit mentores",
		editMentoresDesc:
			"From here you can select the mentors authorized to give support within the Challenge proposals. ",
		selectThis: "Select",
		generalMenu: "General",
		imagenes: "Images",
		documents: "Documents",
		deleteProposal: "Delete proposal",

		settingFor: "Settings for",
		settingForStreamingInfoTitle: "How to correctly include the link for your Streaming channels?",
		settingForStreamingInfoDescParagraph1: "- You must select each corresponding link of each of the platforms.",
		settingForStreamingInfoDescParagraph2: (socialMediaProfileRoot) => (
			<>
				- You must delete the initial part of the link, that means, if you&apos;d for example want to enter a link with
				the following link <b>&quot;{socialMediaProfileRoot}1234567&quot;</b>, you should only enter the last part:{" "}
				<b>&quot;1234567&quot;</b>
			</>
		),

		backToProposal: "Back",
		max4Docs: "Maximum 4 files.",
		pdfFormat: "Files must be either in PDF, PowerPoint, Excel or Word format.",
		limit5MB: "Files cannot exceed 5MB each.",
		addDocumentsDescription: "Attach any attached documents to help better understand your proposal.",
		max: "Max",
		files: "files",
		dragPDF: "Drag and drop the document or just click to select the file.",
		remove: "Remove",
		errorUploadingFiles: "Error uploading the file to the server.",
		deleteWarning: "Once the proposal has been removed, the action cannot be reversed. Please be cautious.",
		beforeContinue: "Before further",
		configureChallenges: "Configure challenges",
		configureChallengesDes:
			"In order to successfully complete the creation of Challenges within de Vinglet you must first configure certain options so that we can give you a better experience.",
		addMentoresToChanllenge: "Space to attach your mentors to the Challenge",
		addMentoresTitle: "Afegeix els mentors corresponents omplint la casella que es troba a continuació.",
		addMentoresDesc:
			"Keep in mind that once the new mentors are applied, the change can no longer be reversed. We advise you to review the added mentors well before continuing, thank you very much.",
		alreadyAttachedMentorDesc:
			"Mentors already attached within the Challenge (this includes the mentors added by the other profiles)",
		advisedProposals: "Advised Proposals",
		noChallengesYet: "Talents have not yet been uploaded for this proposal.",
		challengesDescription: "Enter the description of your proposal right here",
		maxDocsTitle: "You have exceeded the limit of allowed files.",
		maxDocsDescFirstPart: "Only maximum allowed",
		maxDocsDescSecondPart: "documents for the proposal.",
		savedCorrectly: "The data has been saved successfully",
		savedUncorrectly: "The data could not be saved.",
		savedDesc: "The updated data is already in your proposal.",
		noSavedDesc:
			"Sorry for the inconvenience, there was an error from our servers, please consider resubmitting the form again later.",
		challengeCreatedBy: (microBusinessProfileTypes: MicroBusinessProfileTypes) => {
			switch (microBusinessProfileTypes.toLowerCase()) {
				case MicroBusinessProfileTypes.UNIVERSITY:
					return "Challenge created by an University";
				case MicroBusinessProfileTypes.COMPANY:
					return "Challenge created by a Company";
				case MicroBusinessProfileTypes.ACCELERATOR:
					return "Challenge created by an Accelerator";
				case MicroBusinessProfileTypes.INCUBATOR:
					return "Challenge created by an Incubator";
				case MicroBusinessProfileTypes.INSTITUTION:
					return "Challenge created by an Institution";
				default:
					return "";
			}
		},
	},
	ca: {
		challenges: "Reptes",
		configurationTitle: "Configura reptes",
		enableChallengeWithOtherCompanyTitle: 'Habilitar opció "Reptes d\'Empreses"',
		enableChallengeWithOtherCompanyDesc:
			"Vinglet vincula Empreses amb Universitats a través dels reptes que realitzen les Empreses. Moltes empreses internacionals utilitzaran aquesta opció per validar o desenvolupar producte o servei en un altre país on tenen intenció de penetrar i que la competència no ho sàpiga. En el cas, que vostès habilitin aquesta opció, rebran propostes d'empreses on els sol·licitaran els seus serveis per escalar el seu producte o servei al seu país. Així mateix, també ens trobarem amb casos d'empreses nacionals que sol·licitaran els seus serveis per fer la mateixa activitat. Cada vegada que una empresa fa un repte se li posa a disposició la possibilitat de sol·licitar els serveis de les universitats que han signat conveni amb Vinglet. Finalment, és important que identifiquin un correu electrònic per rebre les propostes, així com els sectors comercials que se senten còmodes per a ser vinculats amb les empreses.",
		enableChallengeWithOtherUniversityTitle: 'Habilitar opció "Reptes amb altres universitats"',
		enableChallengeWithOtherUniversityDesc:
			"Vinglet vincula la vostra Universitat amb Universitats nacionals i internacionals a través dels reptes. Cada vegada que una Universitat crea un repte i té habilitada l'opció compartir repte amb una altra Universitat, podrà enviar una sol·licitud perquè una altra Universitat valori la proposta de realitzar el repte de forma conjunta. Tots dos aneu a tenir el perfil d'administrador de l'repte, de manera que tots dos podreu carregar, editar, modificar, eliminar i publicar el repte. Per rebre propostes d'altres Universitats, identifiqui email per rebre les propostes i facultat Universitària.",
		enableChallengeWithOtherIncubatorTitle: 'Habiliteu l\'opció "Desafiaments amb altres incubadores"',
		enableChallengeWithOtherIncubatorDesc:
			"Vinglet vincula la seva Incubadora amb Incubadores nacionals i internacionals a través de reptes i hackathons. Cada vegada que una Incubadora crea un desafiament o hackathon té l'opció de compartir la proposta amb un homòleg que aquest a la nostra base de dades. Podrà enviar una sol·licitud perquè una altra Incubadora avaluï la proposta per dur a terme el repte o hackathon junts. Tots dos tindran el perfil d'administrador de l'desafiament, per la qual cosa tots dos poden carregar, editar, modificar, eliminar i publicar el desafiament. Per rebre propostes d'altres Incubadores, identifiqui un correu electrònic per rebre les propostes i les etiquetes corresponents.",
		enableChallengeWithOtherAcceleratorTitle: 'Habiliteu l\'opció "Desafiaments amb altres acceleradores"',
		enableChallengeWithOtherAcceleratorDesc:
			"Vinglet vincula la seva Acceleradora amb Acceleradores nacionals i internacionals a través de reptes i hackathons. Cada vegada que un Accelerador crea un desafiament o hackathon té l'opció de compartir la proposta amb un homòleg que aquest a la nostra base de dades. Podrà enviar una sol·licitud perquè una altra Acceleradora avaluï la proposta per dur a terme el repte o hackathon junts. Tots dos tindran el perfil d'administrador de l'desafiament, per la qual cosa tots dos poden carregar, editar, modificar, eliminar i publicar el desafiament. Per rebre propostes d'altres Acceleradores, identifiqui un correu electrònic per rebre les propostes i les etiquetes corresponents.",
		enableChallengeWithOtherInstitutionTitle: 'Habiliteu l\'opció "Desafiaments amb altres institucions"',
		enableChallengeWithOtherInstitutionDesc:
			"Vinglet vincula la seva institució amb Institucions nacionals i internacionals a través de reptes i hackathons. Cada vegada que una institució crea un desafiament o hackathon té l'opció de compartir la proposta amb un homòleg que aquest a la nostra base de dades. Podrà enviar una sol·licitud perquè una altra institució avaluï la proposta per dur a terme el repte o hackathon junts. Tots dos tindran el perfil d'administrador de l'desafiament, per la qual cosa tots dos poden carregar, editar, modificar, eliminar i publicar el desafiament. Per rebre propostes d'altres institucions, identifiqui un correu electrònic per rebre les propostes i les etiquetes corresponents.",
		youMustBeEitherCreatorOrInvitedToSeeTheChallenge:
			"Per veure correctament tota la informació d'aquest Repte vostè ha de ser el creador o ser convidat a participar d'aquest.",
		challengesDescriptionIntro: "A l'apartat de descripció podreu detallar la vostra resposta. A més a més, té l'eina d'hipervincles. Enllaços que ens permetran la interconnexió amb altres documents que vulguem mostrar per ajudar a estendre millor la nostra proposta. També serveix per mostrar documents o fitxers que volem compartir si estem buscant conformar equips per donar resposta al repte, innovació oberta, corporate venturing, etc.",
		type: "Tipu",
		enable: "Enable",
		enabled: "Enabled",
		disable: "Disable",
		challengeWithOtherUniversity: "Reptes amb una altra Universitat",
		challengeInviteRestriction: "Només es pot convidar a un Universitat",
		situationApproved: "ACCEPTAT",
		situationDenied: "DENEGAT",
		situationPending: "PENDENT",
		situationFinish: "FINALITZAT",
		startDate: "Data inici",
		endDate: "Data fi",
		inCharge: "responsable",
		invitedUniversity: "Universitat convidada",
		create: "Crear",
		invitedBy: "Convidat per",
		email: "Email",
		acepted: "Acceptat",
		pendings: "Penjolls",
		denied: "Negat",
		finished: "Acabat",
		withoutThematic: "Sense temàtica",
		seeMore: "Veure més",
		thematic: "Temàtica",
		selectedFile: "Arxiu seleccionat",
		missing: "Sense completar",
		dragAndDropFileHelp: "Arrossegueu el llistat de mails o simplement cliqueu per seleccionar l'arxiu.",
		mentors: "Mentors",
		elearning: "E-learning",
		writeNameAndSurname: "Escriu el teu nom i cognoms",
		writeEmail: "Escriu el teu email",
		affair: "Assumpte",
		receiveEmail: "Rebràs una còpia de l'missatge a manera de prova",
		perfilCreador: "Perfil creador",
		perfilInvited: "Perfil convidat",
		platforms: "Plataformes de videoconferències i eines de treball",
		platformsSubtitle:
			"Espai per vincular el vostre perfil de plataforma de videoconferències i eines de treball en equip",
		meetTheTeam: "Conèixer l'equip",
		images: "Adjuntar fotografies",
		imagesDescription:
			"Aquest apartat és perquè puguis mostrar 4 imatges il·lustratives o fotografies que representin la teva proposta per resoldre el repte, una història, un element de contingut, categoria o mòdul. <br> Només documents format JPG o PNG (mida màxima de fitxer: 25 MB)",
		attachedDocument: "Adjuntar document annex",
		attachedDocumentDescription: "Adjunta document annex per ajudar a entendre millor la teva proposta. ",
		importantResponsability:
			"IMPORTANT: no ens fem responsables de marques i patents, li suggerim que no pugi documentació sensible.",
		general: "General",
		myChallenges: "Les meves propostes de repte",
		mentorRelationship: "Relació de mentors",
		solicitedDescription: "Descripció del sol·licitat",
		elearningContent: "Contingut d'e learning",
		listOfProposalsFor: "Llistat de propostes per",
		team: "Equip",
		searching: "Buscant",
		searchMentors: "Cerca mentors",
		searchMentorsDescription:
			'Si el mentor està dins de Vinglet, escriu el nom d\'usuari pel qual s\'ha identificat. Els mentors s\'han de registrar dins Vinglet primer com a persona física i seguidament crear el perfil "Assessor" i identificar cost hora "0".',
		spaceToInfiteUniversityDescription:
			"Espai per convidar a realitzar un Repte a altres Universitats nacionals o internacionals",
		select: "Selecciona",
		selected: "Seleccionat",
		acceptRequest: "Accepta la sol·licitud",
		visit: "Visita",
		challengeSignUp: "Inscriure a l'Repte",
		inviteUniversityAdvice:
			"Si la Universitat accepta la proposta apareixerà vinculat a l'repte. Per contra, sinó ho accepta la proposta rebrà un correu electrònic amb la denegació de la Universitat.",
		addUniversity: "Afegir Universitat",
		addUniversityAdvice:
			'Recorda que aquí es llistaran les universitats que hagin acceptat rebre "Reptes", si la universitat que vostè està buscant no es troba en aquest llistat, li suggerim que es posi en contacte amb la universitat amb la finalitat que puguin realitzar "Reptes"',
		milestones: "Fites",
		milestonesDesc: "Com a mínim 1 fita, i com a màxim 6.",
		noSearchResult: "No hi ha resultats per a la recerca seleccionada encara.",
		adminBy: "Administrat per",
		relatedProfiles: "Perfils involucrats",
		lookForNewProfiles: "Coneix nostres ofertes de Talent",
		attachedDocuments: "Documents annexos",
		knowTheTeam: "Coneix a l'Equip",
		knowTheMentor: "Coneix els mentors",
		seeImage: "Iimatges",
		missingDescription: "Aquesta proposta no té assignat una descripció",
		missingImages: "Aquesta proposta no té imatges carregades",
		admin: "Administrador",
		missingDocuments: "Aquesta proposta no té documents adjunts",
		missingMentors: "Aquesta proposta no té mentors",
		addNewMentores: "Afegir nous mentors",
		editMentores: "Edita mentors",
		editMentoresDesc:
			"Des d'aquí podràs seleccionar els mentors habilitats per donar suport dins de les propostes de l'Repte",
		selectThis: "Seleccionar",
		generalMenu: "General",
		imagenes: "Iimatges",
		documents: "Documents",
		deleteProposal: "Eliminar proposta",

		settingFor: "Configuració de",
		settingForStreamingInfoTitle: "¿Com incloure correctament el link per als teus canals de Streaming?",
		settingForStreamingInfoDescParagraph1:
			"- Ha de seleccionar cada link corresponent de cadascuna de les plataformes.",
		settingForStreamingInfoDescParagraph2: (socialMediaProfileRoot) => (
			<>
				- Ha d&apos;eliminar la part inicial de l&apos;enllaç, és a dir, si vostè ara vol ingressar un link amb el
				següent link <b>&quot;{socialMediaProfileRoot}1234567&quot;</b>, vostè només hauria d&apos;ingressar
				l&apos;última part: <b>&quot;1234567&quot;</b>
			</>
		),

		backToProposal: "Tornar a la proposta",
		max4Docs: "Màxim 4 arxius",
		pdfFormat: "Els arxius han d'estar en format PDF, PowerPoint, Excel o Word",
		limit5MB: "Els arxius no poden superar els 5MB cadascun.",
		addDocumentsDescription: "Adjunta els documents annexos per ajudar a entendre millor la teva proposta.",
		max: "Màxim",
		files: "arxius",
		dragPDF: "Passi el document o simplement cliqueu per seleccionar l'arxiu.",
		remove: "Eliminar",
		errorUploadingFiles: "Error uploading the file to the server.",
		deleteWarning: "Un cop s'hagi eliminat la proposta, l'acció no pot ser revertida. Si us plau sigui prudent.",
		beforeContinue: "Abans de seguir",
		configureChallenges: "Configura repte",
		configureChallengesDes:
			"Per poder concretar amb èxit la creació de Reptes dins de Vinglet primer hauràs configurar certes opcions perquè puguem brindar-te una millor experiència.",
		addMentoresToChanllenge: "Espai per adjuntar les teves mentors a l'Repte",
		addMentoresTitle: "Add the corresponding mentors by filling in the box below.",
		addMentoresDesc:
			"Recordeu que una vegada que s'apliquin els nous mentors ja no es podrà revertir el canvi. Li aconsellem revisar bé els mentors agregats abans de continuar, moltes gràcies.",
		alreadyAttachedMentorDesc:
			"Mentors ja adjuntats dins el Repte (això inclou els mentors agregats pels altres perfils)",
		advisedProposals: "Propostes assessorades",
		noChallengesYet: "Encara no s'han carregat Talents per a aquesta proposta.",
		challengesDescription: "Introduïu la descripció aquí mateix de la seva proposta",
		maxDocsTitle: "Ha superat el límit d'arxius permesos.",
		maxDocsDescFirstPart: "Només s'admeten com a màxim",
		maxDocsDescSecondPart: "documents per a la proposta.",
		savedCorrectly: "S'han guardat les dades amb èxit",
		savedUncorrectly: "No s'han pogut desar les dades.",
		savedDesc: "Ja estan les dades actualitzades dins de la teva proposta.",
		noSavedDesc:
			"Disculpi les molèsties, hi va haver un error des dels nostres servidors, consideri reenviar el formulari de nou més tard.",
		challengeCreatedBy: (microBusinessProfileTypes: MicroBusinessProfileTypes) => {
			switch (microBusinessProfileTypes.toLowerCase()) {
				case MicroBusinessProfileTypes.UNIVERSITY:
					return "Repte creat perfil Universitat";
				case MicroBusinessProfileTypes.COMPANY:
					return "Repte creat perfil Empresa";
				case MicroBusinessProfileTypes.ACCELERATOR:
					return "Repte creat perfil Acceleradora";
				case MicroBusinessProfileTypes.INCUBATOR:
					return "Repte creat perfil Incubadora";
				case MicroBusinessProfileTypes.INSTITUTION:
					return "Repte creat perfil Institució";
				default:
					return "";
			}
		},
	},
	pt: {
		challenges: "Desafios",
		configurationTitle: "Configurar desafios",
		enableChallengeWithOtherCompanyTitle: 'Habilite a opção "Desafios de Negócios"',
		enableChallengeWithOtherCompanyDesc:
			"Vinglet conecta Empresas às Universidades através dos desafios que as Empresas colocam. Muitas empresas internacionais vão usar esta opção para validar ou desenvolver um produto ou serviço em outro país onde pretendem entrar e que a competição não seja conhecida. Caso habilite a opção, você receberá propostas de empresas que solicitarão seus serviços para escalonar seu produto ou serviço em seu país. Do mesmo modo, nós achamos também casos de empresas nacionais que solicitarão os seus serviços para o exercício da mesma atividade. Cada vez que uma Empresa lança um desafio, é disponibilizada a possibilidade de solicitar os serviços das Universidades que celebraram convênio com a Vinglet. Por fim, é importante que coloque um e-mail para receber as propostas, assim como os setores comerciais que se sentem confortáveis em estar vinculados às empresas.",

		enableChallengeWithOtherUniversityTitle: 'Habilite a opção "Desafios com outras Universidades"',
		enableChallengeWithOtherUniversityDesc:
			"Vinglet conecta sua Universidade com Universidades nacionais e internacionais por meio de desafios. Cada vez que uma Universidade cria um desafio e tem a opção de dividir um desafio com outra Universidade habilitada, ela poderá enviar uma solicitação para outra Universidade avaliar a proposta para realizar o desafio em conjunto.	Ambos terão o perfil de administrador do desafio, para que possam fazer upload, editar, modificar, excluir e publicar o desafio. Para receber propostas de outras Universidades, coloque o e-mail para recebimento das propostas e faculdade da universidade.",

		enableChallengeWithOtherIncubatorTitle: 'Habilite a opção "Desafios com outras incubadoras"',
		enableChallengeWithOtherIncubatorDesc:
			"Vinglet vincula sua Incubadora a Incubadoras nacionais e internacionais por meio de desafios e hackathons. Cada vez que uma Incubadora cria um desafio ou hackathon, ela tem a opção de compartilhar a proposta com uma contraparte em nosso banco de dados. Você pode enviar uma solicitação para outra Incubadora para avaliar a proposta de realizar o desafio ou hackathon juntos. Ambos terão o perfil de administrador do desafio, para que possam fazer upload, editar, modificar, excluir e postar o desafio. Para receber propostas de outras Incubadoras, insira um e-mail para receber as propostas e os rótulos correspondentes.",

		enableChallengeWithOtherAcceleratorTitle: 'Habilite a opção "Desafios com outros aceleradores"',
		enableChallengeWithOtherAcceleratorDesc:
			"Vinglet conecta seu acelerador com aceleradores nacionais e internacionais por meio de desafios e hackathons. Cada vez que um acelerador cria um desafio ou hackathon, ele tem a opção de compartilhar a proposta com um colega em nosso banco de dados. Você pode enviar uma solicitação de outro acelerador para revisar a proposta para executar o desafio ou hackathon juntos. Ambos terão o perfil de administrador do desafio, para que possam fazer upload, editar, modificar, excluir e postar o desafio. Para receber propostas de outros aceleradores, insira um e-mail para receber as propostas e os rótulos correspondentes.",

		enableChallengeWithOtherInstitutionTitle: 'Habilite a opção "Desafios com outras instituições"',
		enableChallengeWithOtherInstitutionDesc:
			"Vinglet vincula sua instituição a instituições nacionais e internacionais por meio de desafios e hackathons. Cada vez que uma Instituição cria um desafio ou um hackathon, ela tem a opção de compartilhar a proposta com uma contraparte que está em nosso banco de dados. Você pode enviar uma solicitação para outra Instituição avaliar a proposta de realizar o desafio ou hackathon juntos. Ambos terão o perfil de administrador do desafio, para que possam fazer upload, editar, modificar, excluir e postar o desafio. Para receber propostas de outras Instituições, insira um e-mail para receber as propostas e os rótulos correspondentes.",

		youMustBeEitherCreatorOrInvitedToSeeTheChallenge:
			"Para ver corretamente todas as informações deste Desafio você deve ser o criador ou ser convidado a participar dele.",
		challengesDescriptionIntro: "Na seção de descrição, você pode detalhar sua resposta. Além disso, possui a ferramenta de hiperlinks. Links que nos permitirão interligar com outros documentos que queremos mostrar para ajudar a compreender melhor a nossa proposta. Serve também para mostrar documentos ou ficheiros que queremos partilhar se procuramos formar equipas para responder ao desafio, inovação aberta, corporate venturing, etc.",
		type: "Tipo",
		enable: "Habilite",
		enabled: "Habilitado",
		disable: "Desabilitar",
		challengeWithOtherUniversity: "Desafios com outra universidade",
		challengeInviteRestriction: "Só pode enviar para uma Universidade",
		situationApproved: "ACEITOS",
		situationDenied: "NEGADOS",
		situationPending: "PENDENTES",
		situationFinish: "FINALIZADO",
		startDate: "Data de início",
		endDate: "Data final",
		inCharge: "Responsável",
		invitedUniversity: "Universidade Convidada",
		create: "Criar",
		invitedBy: "Convidado por",
		email: "E-mail",
		acepted: "Aceitaram",
		pendings: "Pendientes",
		denied: "Pendentes",
		finished: "Finalizados",
		withoutThematic: "Sem tema",
		seeMore: "Ver mais",
		thematic: "Temas",
		selectedFile: "Arquivo selecionado",
		missing: "Não preenchido",
		dragAndDropFileHelp: "Arraste a lista de e-mails ou apenas clique para selecionar o arquivo.",
		mentors: "Mentores",
		elearning: "E-learning",
		writeNameAndSurname: "Escreva seu nome e sobrenome",
		writeEmail: "Escreva seu e-mail",
		affair: "Assunto",
		receiveEmail: "Você receberá uma cópia da mensagem como um teste",
		perfilCreador: "Perfil do criador",
		perfilInvited: "Perfil de convidado",
		platforms: "Plataformas de videoconferência e ferramentas de trabalho",
		platformsSubtitle:
			"Espaço para vincular seu perfil de plataforma de videoconferência e ferramentas de trabalho em equipe",
		meetTheTeam: "Conheça o equipe",
		images: "Anexar fotos",
		imagesDescription:
			"Esta seção é para que você possa mostrar 4 imagens ilustrativas ou fotografias que representem sua proposta para resolver o desafio, uma história, um elemento de conteúdo, categoria ou módulo. <br> Apenas documentos no formato JPG ou PNG (tamanho máximo do arquivo: 25 MB)",
		attachedDocument: "Anexar documento anexado",
		attachedDocumentDescription: "Anexe um documento anexo para ajudar a entender melhor sua proposta.",
		importantResponsability:
			"IMPORTANTE: não somos responsáveis por marcas registradas e patentes. Sugerimos que você não carregue documentação sensível.",
		general: "Em geral",
		myChallenges: "Minhas propostas de desafio",
		mentorRelationship: "Relacionamento com Mentores",
		solicitedDescription: "Descrição do pedido",
		elearningContent: "Conteúdo de E-learning",
		listOfProposalsFor: "Lista de propostas para",
		team: "Equipe",
		searching: "Procurando",
		searchMentors: "Encontrar mentores",
		searchMentorsDescription:
			'Se o mentor estiver no Vinglet, digite o nome de usuário pelo qual ele se identificou. Os mentores devem primeiro se registrar no Vinglet como uma pessoa física e, em seguida, criar o perfil "Consultor" e identificar o custo por hora “0”.',
		spaceToInfiteUniversityDescription:
			"Espaço para convidar a realizar um Desafio para outras Universidades nacionais ou internacionais",
		select: "Escolher",
		selected: "Selecionado",
		acceptRequest: "Aceitar pedido",
		visit: "Visita",
		challengeSignUp: "Inscreva-se no desafio",
		inviteUniversityAdvice:
			"Se a Universidade aceitar a proposta, ela aparecerá vinculada ao desafio. Pelo contrário, se a proposta não for aceita, você receberá um e-mail com a negação da Universidade.",
		addUniversity: "Adicionar universidade",
		addUniversityAdvice:
			'Lembre-se que as universidades que aceitaram receber "Desafios" serão listadas aqui, caso a universidade que você procura não esteja nesta lista, sugerimos que você entre em contato com a universidade para que possam realizar "Desafios"',
		milestones: "Marcos",
		milestonesDesc: "Pelo menos 1 marco e no máximo 6.",
		noSearchResult: "Ainda não há resultados para a pesquisa selecionada.",
		adminBy: "Gerenciado por",
		relatedProfiles: "Perfis envolvidos",
		lookForNewProfiles: "Conheça nossas ofertas de talentos",
		attachedDocuments: "Documentos anexados",
		knowTheTeam: "Conheça o equipe",
		knowTheMentor: "Conheça os mentores",
		seeImage: "Imagens",
		missingDescription: "Esta proposta não tem descrição",
		missingImages: "Esta proposta não tem imagens",
		admin: "Administrador",
		missingDocuments: "Esta proposta não possui documentos anexados",
		missingMentors: "Esta proposta não tem mentores",
		addNewMentores: "Adicionar novos mentores",
		editMentores: "Editar Mentores",
		editMentoresDesc:
			"A partir daqui você pode selecionar os mentores habilitados para fornecer suporte dentro das propostas do Desafio.",
		selectThis: "Selecionar",
		generalMenu: "Em geral",
		imagenes: "Imagens",
		documents: "Documentos",
		deleteProposal: "Excluir proposta",
		settingFor: "Configuração de",
		settingForStreamingInfoTitle: "Como incluir corretamente o link para seus canais de streaming?",
		settingForStreamingInfoDescParagraph1:
			"- Você deve selecionar cada link correspondente de cada uma das plataformas",
		settingForStreamingInfoDescParagraph2: (socialMediaProfileRoot) => (
			<>
				- Você deve excluir a parte inicial do link, ou seja, se você, por exemplo, deseja inserir um link com o
				seguinte link <b>&quot;{socialMediaProfileRoot}1234567&quot;</b>, deverá inserir apenas a última parte:
				<b>&quot;1234567&quot;</b>
			</>
		),

		backToProposal: "Voltar para a proposta",
		max4Docs: "Máximo de 4 arquivos",
		pdfFormat: "Os arquivos devem estar em formato PDF, PowerPoint, Excel ou Word",
		limit5MB: "Os arquivos não podem exceder 5 MB cada.",
		addDocumentsDescription: "Anexe os documentos em anexo para ajudar a entender melhor sua proposta.",
		max: "Máximo",
		files: "arquivos",
		dragPDF: "Arraste o documento ou apenas clique para selecionar o arquivo.",
		remove: "Livrar-se de",
		errorUploadingFiles: "Ocorreu um erro ao tentar carregar o arquivo.",
		deleteWarning: "Depois que a proposta for removida, a ação não poderá ser revertida. Por favor, seja cuidadoso.",
		beforeContinue: "Antes de mais nada.",
		configureChallenges: "Configure desafios",
		configureChallengesDes:
			"Para criar Desafios com sucesso no Vinglet, você deve primeiro configurar certas opções para que possamos oferecer uma experiência melhor.",
		addMentoresToChanllenge: "Espaço para anexar seus mentores ao Desafio",
		addMentoresTitle: "Adicione os mentores correspondentes preenchendo a caixa abaixo.",
		addMentoresDesc:
			"Observe que, uma vez que os novos mentores sejam aplicados, a mudança não poderá mais ser revertida. Aconselhamos que você revise bem os mentores adicionados antes de continuar, muito obrigado.",
		alreadyAttachedMentorDesc:
			"Mentores já anexados no Desafio (isso inclui os mentores adicionados pelos outros perfis)",
		advisedProposals: "Propostas Aconselhadas",
		noChallengesYet: "Os talentos ainda não foram carregados para esta proposta.",
		challengesDescription: "Insira aqui a descrição da sua proposta",
		maxDocsTitle: "Você excedeu o limite de arquivos permitidos.",
		maxDocsDescFirstPart: "Apenas um máximo de",
		maxDocsDescSecondPart: "documentos para a proposta.",
		savedCorrectly: "Dados salvos com sucesso",
		savedUncorrectly: "Falha ao salvar dados.",
		savedDesc: "Os dados já estão atualizados na sua proposta.",
		noSavedDesc:
			"Desculpe pelo inconveniente, houve um erro de nossos servidores, considere reenviar o formulário novamente mais tarde.",
		challengeCreatedBy: (microBusinessProfileTypes: MicroBusinessProfileTypes) => {
			switch (microBusinessProfileTypes.toLowerCase()) {
				case MicroBusinessProfileTypes.UNIVERSITY:
					return "Desafio criado por um perfil universitário";
				case MicroBusinessProfileTypes.COMPANY:
					return "Desafio criado por um perfil de empresa";
				case MicroBusinessProfileTypes.ACCELERATOR:
					return "Desafio criado por um perfil do Accelerator";
				case MicroBusinessProfileTypes.INCUBATOR:
					return "Desafio criado por um perfil de Incubadora";
				case MicroBusinessProfileTypes.INSTITUTION:
					return "Desafio criado por um perfil de Instituição";
				default:
					return "";
			}
		},
	},
};

export const EnableCompanyChallengesTexts: Translation<EnableCompanyChallengeTranslation> = {
	es: {
		identifyEmail: "Identifica el email que recibirá las propuestas de las empresas",
		personInCharge: "Responsable anterior identificado",
		services: "Identifica los sectores donde la universidad puede prestar servicios",
	},
	en: {
		identifyEmail: "Identify the email that will receive the proposals from the companies",
		personInCharge: "Previous person in charge identified",
		services: "Identifies the sectors where the university can provide services",
	},
	ca: {
		identifyEmail: "Identifica el email que rebrà les propostes de les empreses",
		personInCharge: "Responsable anterior identificat",
		services: "Identifica els sectors on la universitat pot prestar serveis",
	},
	pt: {
		identifyEmail: "Coloque o e-mail que receberá as propostas das empresas",
		personInCharge: "Responsável anterior identificado",
		services: "Identificar os setores onde a universidade pode prestar serviços",
	},
};

export const EnableUniversityChallengesTexts: Translation<EnableUniversityChallengeTranslation> = {
	es: {
		identifyEmail: "Identifica el email que recibirá las propuestas de las universidades",
		identifyEmailForGenericBP: "Identifica el email que recibirá las propuestas",
		personInCharge: "Escriba la dirección de email",
		services: "Identifica las facultades donde quieres recibir propuestas de relizar un reto con otras universidades",
		proposalsSubmitted: "Propuestas entregadas",
		finished: "Finalizado",
		whatIsRequired: "Qué se solicita",
		prizeOrFratification: "Premio o gratificación",
		responsibleWhoEvaluateTheChallenge: "Responsable que evalúan el reto",
		requirementsToParticipate: "Requisitos para participar",
		deliveryFormat: "Formato de entrega",
		regulationsForThePresentationAndDefenseOfTheChallenge: "Normativa de presentación y defensa del reto",
		spaceToAttachStudentSEmails:
			"Espacio para adjuntar los correos electrónicos de los participantes al reto o hackathon",
		addCSVDescription:
			"Adjunta en formato CSV los emails, en una sola columna y en cada celda un email de las personas que deben hacer el reto o hackathon.",
		addCSVSecondDescription:
			"IMPORTANTE: deben de estar todos los emails en una sola columna y no debe tener separaciones entre celda y celda.",
		emailToUniversityTitle:
			"Escribe por favor, mensaje que quieras hacerle llegar a la Universidad. Tiempo promedio de respuesta de 3 a 7 días laborales.",
		changeUniversity: "Cambiar universidad",
		createChallengeProposalTitle: "Crea tu propuesta de Reto",
		createChallengeProposalSteps: "¡Crea tu propuesta en 3 sencillos pasos!",
		createChallengeProposalDescription1: "realiza una descripción de tu Reto.",
		createChallengeProposalDescription2: "sube documentos y/o fotografías a título ilustrativo.",
		createChallengeProposalDescription3:
			"genera ofertas de talento para captar el mejor equipo. Para ello tendrás una herramienta de procesos de selección.",
		createChallengeProposalFooter:
			"Por último, encontrarás mentores y contenidos de formación e-learning ofrecidos por la Universidad.",

		selectChallengeTitle: "Elige un título que identifica tu propuesta para resolver el Reto",
		selectChallengeTitleDescripion: "No te preocupes mucho por el título, lo podrás editar siempre sino te gusta",
		writeChallengeTitle: "Escribe el título de tu Reto (70 palabras máximo)",

		challengeProposalCreationProblem: "No se pudo crear la propuesta, vuelva a intentarlo, disculpe las molestias.",

		enableChallengeWithOtherAcceleratorIdentifyEmailTitle:
			"Identifica el mail de la Aceleradora la cual recibirá las propuestas del Reto o Hackaton.",
		enableChallengeWithOtherIncubatorIdentifyEmailTitle:
			"Identifica el mail de la Incubadora la cual recibirá las propuestas del Reto o Hackaton.",
		enableChallengeWithOtherInstitutionIdentifyEmailTitle:
			"Identifica el mail de la Institución la cual recibirá las propuestas del Reto o Hackaton.",
	},
	en: {
		identifyEmail: "Identify the email that will receive the proposals from the universities",
		identifyEmailForGenericBP: "Identify the email that will receive the proposals",
		personInCharge: "Enter email address",
		services: "Identify the faculties where you want to receive proposals to make a challenge with other universities",
		proposalsSubmitted: "Proposals submitted",
		finished: "Finished",
		whatIsRequired: "What is required",
		prizeOrFratification: "Prize or gratification",
		responsibleWhoEvaluateTheChallenge: "Responsible who evaluate the challenge",
		requirementsToParticipate: "Requirements to participate",
		deliveryFormat: "Delivery format",
		regulationsForThePresentationAndDefenseOfTheChallenge:
			"Regulations for the presentation and defense of the challenge",
		spaceToAttachStudentSEmails: "Space to attach the emails of the participants to the challenge or hackathon",
		addCSVDescription:
			"Attach the emails in CSV format, in a single column and in each cell an email of the people who must do the challenge or hackathon.",
		addCSVSecondDescription:
			"IMPORTANT: all emails must be in a single column and must not have separations between cell and cell.",
		emailToUniversityTitle:
			"Escribe por favor, mensaje que quieras hacerle llegar a la Universidad. Tiempo promedio de respuesta de 3 a 7 días laborales.",
		changeUniversity: "Change university",
		createChallengeProposalTitle: "Create your Challenge proposal",
		createChallengeProposalSteps: "Create your proposal in 3 easy steps!",
		createChallengeProposalDescription1: "make a description of your Challenge.",
		createChallengeProposalDescription2: "upload documents and / or photographs for illustrative purposes.",
		createChallengeProposalDescription3:
			"generate talent offers to attract the best team. For this you will have a selection process tool.",
		createChallengeProposalFooter:
			"Finally, you will find mentors and e-learning training content offered by the University.",

		selectChallengeTitle: "Choose a title that identifies your proposal to solve the Challenge",
		selectChallengeTitleDescripion: "Don't worry too much about the title, you can always edit it if you don't like it",
		writeChallengeTitle: "Write the title of your Challenge (70 words maximum)",

		challengeProposalCreationProblem:
			"The proposal could not be created, please try again, sorry for the inconvenience.",

		enableChallengeWithOtherAcceleratorIdentifyEmailTitle:
			"Identify the email of the Accelerator which will receive the Challenge or Hackathon proposals.",
		enableChallengeWithOtherIncubatorIdentifyEmailTitle:
			"Identify the email of the Incubator which will receive the Challenge or Hackathon proposals.",
		enableChallengeWithOtherInstitutionIdentifyEmailTitle:
			"Identifies the email of the Institution which will receive the Challenge or Hackathon proposals.",
	},
	ca: {
		identifyEmail: "Identifica el email que rebrà les propostes de les universitats",
		identifyEmailForGenericBP: "Identifica el email que rebrà les propostes",
		personInCharge: "Escriviu l'adreça de correu electrònic",
		services: "Identifica les facultats on vols rebre propostes d'relitzar un repte amb altres universitats",
		proposalsSubmitted: "Propostes entregades",
		finished: "Finalitzat",
		whatIsRequired: "Què es demana",
		prizeOrFratification: "Premi o gratificació",
		responsibleWhoEvaluateTheChallenge: "Responsable que avaluen el repte",
		requirementsToParticipate: "Requisits per a participar",
		deliveryFormat: "Format de lliurament",
		regulationsForThePresentationAndDefenseOfTheChallenge: "Normativa de presentació i defensa el repte",
		spaceToAttachStudentSEmails: "Espai per adjuntar els correus electrònics dels participants a l'repte o hackathon",
		addCSVDescription:
			"Adjunta en format CSV els emails, en una sola columna i en cada cel·la un correu electrònic de les persones que han de fer el repte o hackathon.",
		addCSVSecondDescription:
			"IMPORTANT: han d'estar tots els emails en una sola columna i no ha de tenir separacions entre cel·la i cel·la.",
		emailToUniversityTitle:
			"Escribe por favor, mensaje que quieras hacerle llegar a la Universidad. Tiempo promedio de respuesta de 3 a 7 días laborales.",
		changeUniversity: "Cambiar universitat",
		createChallengeProposalTitle: "Crea proposta de Repte",
		createChallengeProposalSteps: "Crea el teu proposta en 3 senzills passos!",
		createChallengeProposalDescription1: "realitza una descripció de la teva Repte.",
		createChallengeProposalDescription2: "puja documents i / o fotografies a títol il·lustratiu.",
		createChallengeProposalDescription3:
			"genera ofertes de talent per captar el millor equip. Per a això tindràs una eina de processos de selecció.",
		createChallengeProposalFooter:
			"Finalment, trobaràs mentors i continguts de formació e-learning oferts per la Universitat.",

		selectChallengeTitle: "Tria un títol que identifica la teva proposta per resoldre el Repte",
		selectChallengeTitleDescripion: "No et preocupis molt pel títol, el podràs editar sempre sinó t'agrada",
		writeChallengeTitle: "Escriu el títol del teu Repte (70 paraules màxim)",

		challengeProposalCreationProblem: "No s'ha pogut crear la proposta, torni a intentar-ho, disculpi les molèsties.",

		enableChallengeWithOtherAcceleratorIdentifyEmailTitle:
			"Identifica el mail de la Acceleradora la qual rebrà les propostes de l'Repte o Hackaton.",
		enableChallengeWithOtherIncubatorIdentifyEmailTitle:
			"Identifica el mail de la Incubadora la qual rebrà les propostes de l'Repte o Hackaton.",
		enableChallengeWithOtherInstitutionIdentifyEmailTitle:
			"Identifica el mail de la institució la qual rebrà les propostes de l'Repte o Hackaton.",
	},
	pt: {
		identifyEmail: "Identificar o e-mail que receberá as propostas das universidades",
		identifyEmailForGenericBP: "Identificar o e-mail que receberá as propostas",
		personInCharge: "Insira o endereço de e-mail",
		services: "Identificar as faculdades onde quer receber propostas para fazer um desafio com outras universidades",
		proposalsSubmitted: "Propostas entregues",
		finished: "Finalizado",
		whatIsRequired: "O que é pedido",
		prizeOrFratification: "Prêmio ou gratificação",
		responsibleWhoEvaluateTheChallenge: "Responsável por avaliar o desafio",
		requirementsToParticipate: "Requisitos para participar",
		deliveryFormat: "Formato de entrega",
		regulationsForThePresentationAndDefenseOfTheChallenge: "Regulamento para a apresentação e defesa do desafio",
		spaceToAttachStudentSEmails: "Espaço para anexar os e-mails dos participantes ao desafio ou hackathon",
		addCSVDescription:
			"Anexe os e-mails em formato CSV, em uma única coluna e em cada célula um e-mail das pessoas que devem realizar o desafio ou hackathon.",
		addCSVSecondDescription:
			"IMPORTANTE: todos os e-mails devem estar em uma única coluna e não devem ter separações célula-célula.",
		emailToUniversityTitle:
			"Por favor, escreva a mensagem que deseja enviar para a Universidade. Tempo médio de resposta de 3 a 7 dias úteis.",
		changeUniversity: "Mudar de universidade",
		createChallengeProposalTitle: "Crie sua proposta de desafio",
		createChallengeProposalSteps: "Crie sua proposta em 3 simples passos!",
		createChallengeProposalDescription1: "faça uma descrição do seu Desafio.",
		createChallengeProposalDescription2: "faça upload de documentos e / ou fotografias para fins ilustrativos.",
		createChallengeProposalDescription3:
			"gerar ofertas de talentos para atrair a melhor equipe. Para isso, você terá uma ferramenta de processo de seleção.",
		createChallengeProposalFooter:
			"Por fim, você encontrará mentores e conteúdo de treinamento de e-learning oferecido pela Universidade.",

		selectChallengeTitle: "Escolha um título que identifique sua proposta para resolver o Desafio",
		selectChallengeTitleDescripion: "Não se preocupe muito com o título. Você sempre pode editá-lo se não gostar",
		writeChallengeTitle: "Escreva o título do seu desafio (70 palavras no máximo)",

		challengeProposalCreationProblem: "Não foi possível criar a proposta. Tente novamente, desculpe o inconveniente.",

		enableChallengeWithOtherAcceleratorIdentifyEmailTitle:
			"Identifica o e-mail da Aceleradora que receberá as propostas do Desafio ou Hackathon.",
		enableChallengeWithOtherIncubatorIdentifyEmailTitle:
			"Identifica o correio da Incubadora que receberá as propostas do Desafio ou Hackathon.",
		enableChallengeWithOtherInstitutionIdentifyEmailTitle:
			"Identifica o correio da Instituição que receberá as propostas do Desafio ou Hackathon.",
	},
};

export const ChallengesProfileTexts: Translation<ChallengesProfileTranslation> = {
	es: {
		noAvailableChallengesToShow: "No hay ningun reto disponible.",
		noInvitesToShow: "No hay invitaciones disponibles para mostrar.",
		inviteForChallenge: "Invitación para el reto",
		myChallenges: "Retos propios",
		invites: "Invitaciones",
		accept: "Aceptar",
		reject: "Rechazar",
	},
	en: {
		noAvailableChallengesToShow: "There are no challenges available.",
		noInvitesToShow: "There are no invitations available to display.",
		inviteForChallenge: "Challenge invite",
		myChallenges: "Own challenges",
		invites: "Invites",
		accept: "Accept",
		reject: "Reject",
	},
	ca: {
		noAvailableChallengesToShow: "No hi ha ningú disponible.",
		noInvitesToShow: "No hi ha invitacions disponibles per mostrar.",
		inviteForChallenge: "Invitació per al repte",
		myChallenges: "Retos propis",
		invites: "Invitacions",
		accept: "Aceptar",
		reject: "Rebutjar",
	},
	pt: {
		noAvailableChallengesToShow: "Não há desafios disponíveis.",
		noInvitesToShow: "Não há convites disponíveis para exibição.",
		inviteForChallenge: "Convite de desafio",
		myChallenges: "Próprios desafios",
		invites: "Convites",
		accept: "Aceitar",
		reject: "Rejeitar",
	},
};
